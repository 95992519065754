<template>
  <div class="rankingList-listContent">
    <div class="grid-content bg-purple-dark" style="margin: 0 auto">
      <!-- @tab-click="handleClick" -->
      <el-tabs
        v-model="activeName"
        style="height: 300px; overflow: hidden; overflow-y: scroll"
        @tab-click="setData"
      >
        <el-tab-pane name="first">
          <span slot="label">
            <div class="parkLable" style="position: relative">
              城市
              <span
                @click.stop="
                  activeStatus === 1 ? (activeStatus = 2) : (activeStatus = 1);
                  activeStatus2 = 2;
                  activeStatus3 = 2;
                  getRankList(1, activeStatus);
                "
                :class="{ isActive: activeStatus === 1 }"
                class="parkLable_top"
              />
              <span
                @click.stop="
                  activeStatus === 0 ? (activeStatus = 2) : (activeStatus = 0);
                  activeStatus2 = 2;
                  activeStatus3 = 2;
                  getRankList(1, activeStatus);
                "
                :class="{ isActive: activeStatus === 0 }"
                class="parkLable_bottom"
              />
            </div>
          </span>
          <el-row v-for="(item, index) in parkings.slice(0, 10)" :key="index" class="row">
            <el-col class="col">
              <span class="indexNumber">{{ index + 1 }}</span>
              <span class="item">{{ item.areaTypeName }}</span>
              <span class="itemMoney">{{ `${item.parkingCount} | ${item.parkingTime}` }}</span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane name="second">
          <span slot="label">
            <div class="parkLable" style="position: relative">
              区域
              <span
                @click.stop="
                  activeStatus2 === 1 ? (activeStatus2 = 2) : (activeStatus2 = 1);
                  activeStatus = 2;
                  activeStatus3 = 2;
                  getRankList(2, activeStatus2);
                "
                :class="{ isActive: activeStatus2 === 1 }"
                class="parkLable_top"
                style="right: -6px"
              />
              <span
                @click.stop="
                  activeStatus2 === 0 ? (activeStatus2 = 2) : (activeStatus2 = 0);
                  activeStatus = 2;
                  activeStatus3 = 2;
                  getRankList(2, activeStatus2);
                "
                :class="{ isActive: activeStatus2 === 0 }"
                class="parkLable_bottom"
                style="right: -6px"
              />
            </div>
          </span>
          <el-row v-for="(item, index) in parkings.slice(0, 10)" :key="index" class="row">
            <el-col class="col">
              <span class="indexNumber">{{ index + 1 }}</span>
              <span class="item">{{ item.areaTypeName }}</span>
              <span class="itemMoney">{{ `${item.parkingCount} | ${item.parkingTime}` }}</span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane name="third">
          <span slot="label">
            <div class="parkLable" style="padding-right: 30px">
              道路/车场
              <span
                @click.stop="
                  activeStatus3 === 1 ? (activeStatus3 = 2) : (activeStatus3 = 1);
                  activeStatus = 2;
                  activeStatus2 = 2;
                  getRankList(3, activeStatus3);
                "
                :class="{ isActive: activeStatus3 === 1 }"
                class="parkLable_top"
                style="right: 14px"
              />
              <span
                @click.stop="
                  activeStatus3 === 0 ? (activeStatus3 = 2) : (activeStatus3 = 0);
                  activeStatus = 2;
                  activeStatus2 = 2;
                  getRankList(3, activeStatus3);
                "
                :class="{ isActive: activeStatus3 === 0 }"
                class="parkLable_bottom"
                style="right: 14px"
              />
            </div>
          </span>
          <el-row v-for="(item, index) in parkings.slice(0, 10)" :key="index" class="row">
            <el-col class="col">
              <span class="indexNumber">{{ index + 1 }}</span>
              <span class="item">{{ item.areaTypeName }}</span>
              <span class="itemMoney">{{ `${item.parkingCount} | ${item.parkingTime}` }}</span>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane disabled label="停车次数(次)" name="a"> </el-tab-pane>
        <el-tab-pane disabled label="平均停车时长" name="b"> </el-tab-pane>
      </el-tabs>
    </div>
    <!-- <div class="berthNumer">停车次数(次)</div>
    <div class="turns">平均停车时长</div> -->
  </div>
</template>
<script>
import { formatDuring, formatterParkCount } from "@/common/js/public.js";
import ranking1 from "@/assets/img/ranking1.png";
import ranking2 from "@/assets/img/ranking2.png";
import ranking3 from "@/assets/img/ranking3.png";
export default {
  props: {
    parking: {
      default: function () {
        // 街道
        return [];
      },
    },
    rankingNumber: {
      default: function () {
        return [];
      },
    },
    activeNameTwo: {
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      parkings: [],
      Number: 0,
      activeName: "first",
      ranking1: ranking1,
      ranking2: ranking2,
      ranking3: ranking3,
      activeStatus: 3,
      activeStatus2: 2,
      activeStatus3: 2,
    };
  },
  watch: {
    parking: {
      handler(val) {
        this.parkings = JSON.parse(JSON.stringify(val));
        this.parkings.forEach((item) => {
          item.parkingTime = formatDuring(item.parkingTime, "seconds");
          item.parkingCount = formatterParkCount(item.parkingCount)
        });
        console.log(val, "88888888");
      },
      immediate: true,
      deep: true,
    },
    rankingNumber: {
      handler(curVal, oldVal) {
        this.Number = curVal;
      },
      immediate: true,
    },
    activeNameTwo: {
      handler(curVal, oldVal) {
        if (curVal === "first") {
          this.activeName = "first";
        } else {
          this.activeName = "first";
        }
      },
      immediate: true,
    },
  },
  methods: {
    setData(tab, event) {
      if (this.Number === 2) {
        var num = 2;
        if (tab.name == "first") {
          this.$emit("getranking", 1, num, null);
        } else if (tab.name == "second") {
          this.$emit("getranking", 2, num, null);
        } else if (tab.name == "third") {
          this.$emit("getranking", 3, num, null);
        }
      }
    },
    getRankList(areaType, desc) {
      switch (areaType) {
        case 1:
          this.activeName = "first";
          break;
        case 2:
          this.activeName = "second";
          break;
        case 3:
          this.activeName = "third";
          break;
        default:
          break;
      }
      this.$emit("getranking", areaType, 2, desc);
    },
  },
};
</script>
<style lang="stylus" rel="stylesheet/stylus">
.rankingList-listContent {
  position: relative;

  .el-tabs__nav {
    .el-tabs__item {
      width: 100px;
    }

    text-align: center;
  }

  .berthNumer {
    position: absolute;
    top: 22px;
    right: 170px;
  }

  .turns {
    position: absolute;
    top: 22px;
    right: 20px;
  }
}
</style>
<style scoped lang="stylus" rel="stylesheet/stylus">
.rankingList-listContent {
  .grid-content {
    height: 295px;
    // border:1px solid #EFE5F9;
    padding: 10px 0;

    .lj {
      float: right;
      margin-top: -30px;
    }

    .row:nth-last-child(1) {
      border-bottom: none;
      margin: 0 0;
    }

    .row {
      width: 100%;
      margin: 10px 0;
      border-bottom: 1px solid #EFE5F9;
      height: 40px;
      line-height: 18px;
      color: #333333;

      .col {
        width: 100%;

        .indexNumber {
          display: inline-block;
          width: 29px;
          height: 34px;
          line-height: 35px;
          text-align: center;
          margin-right: 10px;
          font-size: 15px;
        }

        .item {
          font-size: 15px;
          position: absolute;
          top: 8px;
        }

        .itemMoney {
          float: right;
          font-size: 15px;
          height: 34px;
          line-height: 35px;
        }
      }
    }
  }
}

.parkLable_top.isActive {
  border-bottom-color: #0f6eff !important;
}

.parkLable_bottom.isActive {
  border-top-color: #0f6eff !important;
}
</style>
