<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper marginB20" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <!-- <el-form-item :label="$t('searchModule.region')" prop="dateType">
            <el-select v-model.trim="areaId" filterable placeholder="请选择" @change="areaChange">
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in areaList"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.region')" prop="dateType">
            <el-select
              v-model.trim="streetId"
              @change="streetChange"
              filterable
              placeholder="请选择"
            >
              <el-option label="全部" value=""></el-option>
              <el-option
                :label="area.areaName"
                :value="area.areaId"
                :key="area.areaId"
                v-for="area in streetList"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item :label="$t('searchModule.region')" prop="areaId">
            <a-cascader ref="cascader" @change="parkClear"></a-cascader>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Belonging_operator')">
            <a-operation-select ref="operationSelect" @change="parkClear"></a-operation-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Type_of_parking_lot')">
            <a-park-type-select ref="parkTypeSelect" @change="parkClear"></a-park-type-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Road_or_Parking_Lot')">
            <a-park-select
              ref="parkSelect"
              :instance="this"
              parkTypeRefName="parkTypeSelect"
              operationRefName="operationSelect"
              areaRefName="cascader"
            ></a-park-select>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Date_search')">
            <a-date-picker
              ref="datePicker"
              :isPreviousday="true"
              :selectkeys="selectkeys"
            ></a-date-picker>
          </el-form-item>
          <!-- <el-form-item :label="$t('searchModule.Date_search')" prop="dateType">
            <el-select
              v-model.trim="formInline.dateType"
              filterable
              placeholder=""
              @change="getTimeNow"
            >
              <el-option label="日" value="day"></el-option>
              <el-option label="月" value="month"></el-option>
            </el-select>
          </el-form-item> -->
          <!-- <el-form-item label="" prop="date">
            <timeRangePick
              @timeChange="timeChange"
              ref="timeRangePicker"
              :defalutDate="defaultTime"
              :type="formInline.dateType == 'day' ? 'daterange' : 'monthrange'"
              :valueFormat="formInline.dateType == 'day' ? 'yyyy-MM-dd' : 'yyyy-MM'"
            />
          </el-form-item> -->
          <el-form-item>
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="
                page = 1;
                getData();
              "
              :loading="loading"
              >{{ $t('button.search') }}</el-button
            >
            <el-button type="primary" icon="el-icon-refresh" @click="empty()" :loading="loading"
              >{{ $t('button.reset') }}</el-button
            >
            <!-- <el-button type="success" icon="el-icon-upload2" v-if='$route.meta.authority.button.export'
                       @click="exportFile()">导出
            </el-button> -->
          </el-form-item>
        </el-form>
      </div>
      <!-- 柱形图 -->
      <cylindricalchart
        class="graphShadow paddingT10 paddingL20"
        :nightParktableData="durationAnalysis"
        :type="type3"
      ></cylindricalchart>
      <div style="margin: 20px 0">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="graphShadow paddingT10 paddingLR20">
              <div class="flexBetween">
                <graphTitle :title="'日均泊位周转次数排行榜'"></graphTitle>
                <el-button
                  type="primary"
                  size="mini"
                  @click="exportFileList(1)"
                  v-if="$route.meta.authority.button.export"
                  >{{ $t('button.export') }}</el-button
                >
              </div>
              <rankingList
                :activeNameTwo="activeName"
                :rankingNumber="rankingNumber"
                :parking="runking"
                @getranking="getranking"
              ></rankingList>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="graphShadow paddingT10 paddingLR20">
              <div class="flexBetween">
                <graphTitle :title="'平均停车时长排行榜'"></graphTitle>
                <el-button
                  size="mini"
                  type="primary"
                  @click="exportFileList(2)"
                  v-if="$route.meta.authority.button.export"
                  >{{ $t('button.export') }}</el-button
                >
              </div>
              <rankingListTwo
                :activeNameTwo="activeName"
                :rankingNumber="rankingNumber"
                :parking="runkingTwo"
                @getranking="getranking"
              ></rankingListTwo>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
// import timeRangePick from "@/components/timePicker";
import {
  exportExcelNew,
  dateFormat,
  //   setIndex
} from "@/common/js/public.js";
import cylindricalchart from "./cylindricalchart.vue";
import autoComplete from "@/components/autocomplete";
import graphTitle from "@/components/graphTitle";

import rankingList from "./rankingList";
import rankingListTwo from "./rankingListTwo";
export default {
  name: "parkIncomeDayStatics",
  data() {
    const startTime = new Date();
    startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    const endTime = new Date();
    endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    return {
      selectkeys: ["date", "month", "week", "year"],
      defaultTime: [startTime, endTime],
      activeName: "first",
      rankingNumber: 0,
      areaId: "",
      streetId: "",
      areaList: [],
      streetList: [],
      durationAnalysis: [],
      parkData: [],
      // 折线图样式
      type3: "c3",
      type2: "c2",
      arrearageStrip: [],
      temParkData: {},
      modelvalue: "",
      tableData: [],
      runking: [],
      runkingTwo: [],
      pName: "",
      index: 0,
      page: 1,
      startDate: "",
      endDate: "",
      pageSize: 15,
      total: 0,
      loading: false,
      detailFlag: false,
      formInline: {
        dateType: "day",
        startTime: "",
        endTime: "",
        // date1: dateFormat(startTime, "yyyy-MM"),
        // date2: dateFormat(endTimeme, "yyyy-MM"),
        // // date2: endTimeme,
        // day1: dateFormat(startTime, "yyyy-MM-dd"),
        // day2: dateFormat(endTimeme, "yyyy-MM-dd"),
        // day2: endTimeme,
        parkId: "",
      },
      desc1: "",
      desc2: "",
    };
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
  },
  methods: {
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    parkClear() {
      this.$refs.parkSelect.clear();
    },
    empty() {
      this.areaId = "";
      this.streetId = "";
      this.modelvalue = "";
      this.formInline.dateType = "day";
      this.streetList = [];
      // this.$refs.timeRangePicker.resetTime();
      this.$refs.cascader.clear();
      this.$refs.operationSelect.clear();
      this.$refs.datePicker.clear();
      this.$refs.parkTypeSelect.clear();
      this.$refs.parkSelect.clear();
      // this.formInline.date1 = new Date().getTime() - 31 * 24 * 60 * 60 * 1000
      // this.formInline.date2 = new Date()
      // this.formInline.day1 = new Date().getTime() - 31 * 24 * 60 * 60 * 1000
      // this.formInline.day2 = new Date()
    },
    getranking(val, num, desc) {
      // let url = "/acb/2.0/parkingAnalysis/berthRank";
      // let url2 = "/acb/2.0/parkingAnalysis/parkingRank";
      let url = "/acb/2.0/aceBerth/berthRank";
      let url2 = "/acb/2.0/aceBerth/parkingRank";
      const tempData = {
        areaType: val,
        streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
      };
      if (desc === 0 || desc === 1) {
        if (num === 1) {
          this.desc1 = desc;
        } else {
          this.desc2 = desc;
        }
        tempData.desc = desc;
      }
      this.$axios
        .get(num === 1 ? url : url2, {
          data: tempData,
        })
        .then((res) => {
          if (res.state == 0) {
            if (num === 1) {
              this.runking = res.value ?? [];
            } else {
              this.runkingTwo = res.value ?? [];
            }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 导出
    exportFileList(num) {
      const tempData = {
        // areaId: this.areaId,
        // streetId: this.streetId,
        streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
        areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
      };
      if (num === 1) {
        if (this.desc1 === 0 || this.desc1 === 1) tempData.desc = this.desc1;
      } else {
        if (this.desc2 === 0 || this.desc2 === 1) tempData.desc = this.desc2;
      }
      let url = "";
      if (num === 1) {
        // url = "/acb/2.0/parkingAnalysis/exportBerthRank"; 老接口
        url = "/acb/2.0/aceBerth/exportBerthRank";
      } else {
        // url = "/acb/2.0/parkingAnalysis/exportParkingRank"; 老接口
        url = "/acb/2.0/aceBerth/exportParkingRank";
      }
      exportExcelNew(url, tempData);
    },
    getAreaList() {
      let url = "/acb/2.0/systems/loginUser/initAreaList";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          if (res.value.depth == 3) {
            this.areaList = res.value.areaList;
          } else {
            this.streetList = res.value.areaList;
          }
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    setParkNull() {
      this.formInline.parkId = "";
      this.modelvalue = "";
      this.temParkData = {};
    },
    areaChange(areaId) {
      this.parkPage = 1;
      this.streetList = [];
      this.streetId = "";
      this.setParkNull();
      if (!areaId) return;
      this.getStreet(areaId);
    },
    streetChange(streetId) {
      this.parkPage = 1;
      this.setParkNull();
      this.getPark(streetId);
    },
    // 请求区域数据
    getStreet(areaId) {
      let url = "/acb/2.0/systems/loginUser/getChildrenArea";
      this.$axios
        .get(url, {
          data: {
            areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.streetList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getPark(areaId) {
      this.area = areaId;
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: this.parkPage,
            pageSize: this.pageSize,
            areaIds: areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (this.parkPage == 1) {
              this.$refs.select.setNull();
            }
            this.parkData = res.value.list;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getTimeNow() {
      // const startTime = new Date();
      // const endTime = new Date();
      // if (this.formInline.dateType == "day") {
      //   startTime.setTime(startTime.getTime() - 3600 * 1000 * 24 * 30);
      //   endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
      // } else {
      //   startTime.setTime(startTime.getTime() - 365 * 24 * 3600 * 1000);
      //   endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
      // }
      // this.formInline.date1 = dateFormat(startTime, "yyyy-MM");
      // this.formInline.date2 = dateFormat(endTime, "yyyy-MM");
    },
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      let areaId = "";
      if (this.$refs.cascader) {
        areaId = this.$refs.cascader.getStreetId()
          ? this.$refs.cascader.getStreetId()
          : this.$refs.cascader.getAreaId();
      }
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            slaveRelations: "0,1",
            areaIds: areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            // this.parkData = res.value.list;
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    // 时间判断
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time = new Date(this.formInline.endTime) - new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        let diff = "";
        if (this.formInline.dateType === "day") {
          if (time >= 31) {
            this.$alert("查询时间不能大于31天");
            return false;
          } else {
            return true;
          }
        } else {
          if (time > 365) {
            this.$alert("查询时间不能大于12个月");
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
    },
    getData() {
      if (this.activeName === "first") {
        this.activeName = "firstq";
      } else {
        this.activeName = "first";
      }
      if (!this.formInline.parkId && Object.keys(this.temParkData).length) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      // let flag = this.showLog();
      // if (!flag) return;
      // console.log(this.formInline.day2)
      // console.log(this.formInline.date2)
      let url = "/acb/2.0/parkingAnalysis/duration";
      this.loading = true;
      this.rankingNumber = 2;
      this.getranking(1, 1, null);
      this.getranking(1, 2, null);
      // this.$axios
      //   .post(url, {
      //     data: {
      //       areaType: "1",
      //       streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
      //       areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
      //       parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
      //       dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
      //       operationId: this.$refs.operationSelect
      //         ? this.$refs.operationSelect.getOperationId()
      //         : "",
      //       dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
      //       startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
      //       endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
      //     },
      //   })
      //   .then((res) => {
      //     this.rankingNumber = 2;
      //     this.loading = false;
      //     this.tableData = res.data;
      //     this.runking = res.value.berthRank;
      //     this.runkingTwo = res.value.parkingRank;
      //   });
      this.getParkDuration();
    },
    // 平均停车时长分析
    getParkDuration() {
      let url = "/acb/2.0/parkingDuration/parkDuration";
      this.loading = true;
      this.$axios
        .get(url, {
          data: {
            areaType: "1",
            streetId: this.$refs.cascader ? this.$refs.cascader.getStreetId() : "",
            areaId: this.$refs.cascader ? this.$refs.cascader.getAreaId() : "",
            parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
            dataSource: this.$refs.parkTypeSelect ? this.$refs.parkTypeSelect.getParkType() : "",
            operationId: this.$refs.operationSelect
              ? this.$refs.operationSelect.getOperationId()
              : "",
            dateType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
            beginDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
            endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
          },
        })
        .then((res) => {
          this.loading = false;
          this.durationAnalysis = res.value ?? [];
        });
    },
    exportFile() {
      let url = "/acop/2.0/financeStatistic/parks/report/export";
      let opt = {};
      opt = {
        operationId: this.$refs.operationSelect ? this.$refs.operationSelect.getOperationId() : "",
        parkId: this.$refs.parkSelect ? this.$refs.parkSelect.getParkId() : "",
        timeType: this.$refs.datePicker ? this.$refs.datePicker.getDateType() : "",
        startDate: this.$refs.datePicker ? this.$refs.datePicker.getStartDate() : "",
        endDate: this.$refs.datePicker ? this.$refs.datePicker.getEndDate() : "",
      };
      exportExcelNew(url, opt);
    },
    receiveData(data) {
      this.formInline.parkId = data.value.parkId;
    },
  },
  beforeDestroy() {},
  components: {
    rankingList,
    cylindricalchart,
    rankingListTwo,
    // timeRangePick,
    graphTitle,
  },
  created() {
    // this.getParkList();
    // this.getTimeNow();
    this.getAreaList();
  },
  mounted() {
    this.getData();
  },
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb
  height 35px
.parkStatisticbg1
  background #68ce62
.parkStatisticbg2
  background #e6a23c
.parkStatisticbg3
  background #3a8ee6
.parkStatisticbg4
  background #FF3300
.parkStatistic
  width 150px
  height 150px
  line-height 150px
  border-radius 50%
  text-align center
  display inline-block
  margin-bottom 40px
  color #fff
  span
    font-size 40px
.content
  overflow hidden
  .searchWrapper
    overflow hidden
    padding 22px 22px 0
    .list
      margin-top 20px
      .el-col
        text-align center
        padding-bottom 18px
        .grid-content
          padding-top 15px
          height 100%
        h2
          font-size 18px
          text-align center
  .pagerWrapper
    text-align right
    margin-top 28px
    font-size 12px
    .export
      font-size 12px
      .iconfont
        margin-right 0
.detail
  width 61.4%
  height 82.9%
  position absolute
  top 8.6%
  left 20.1%
  background #FFFFFF
  box-shadow 0 0 4px 1px rgba(128, 145, 165, 0.3)
  border-radius 3px
  z-index 1000
  box-sizing border-box
  padding 20px 0
  .title
    position absolute
    background #3F4A56
    border-radius 3px 3px 0 0
    color #fff
    width 100%
    top 0
    left 0
    height 40px
    line-height 40px
    font-size 14px
    padding 0 12px
    box-sizing inherit
    z-index 100
    .el-icon-close
      float right
      margin-top 14px
  .info
    margin-top 20px
    padding 2.1%
    height 100%
    overflow auto
    box-sizing inherit
.mask
  background rgba(49, 53, 65, 0.6)
  position fixed
  top 0
  bottom 0
  left 0
  right 0
  z-index 999
.el-dropdown-link
  cursor pointer
</style>
