<template>
  <div>
    <graphTitle class="title" :title="'停车时长分析'">
      <el-tooltip placement="top-start">
        <div slot="content">
          停车次数：展示统计期间内，各个停车时长区间内的停车记录数<br />
          停车时长：展示统计时间段内，在各个停车时长区间内的停车记录的总时长。
        </div>
        <i class="el-icon-question my-icon" />
      </el-tooltip>
    </graphTitle>
    <div id="chart" class="cylindricalchart"></div>
  </div>
</template>
<script>
// import hub from "./../../common/js/hub"
import graphTitle from "@/components/graphTitle";

export default {
  props: {
    nightParktableData: {
      // type: Array,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      dateList: [],
      seriesValue3: [
        {
          name: "停车次数",
          type: "bar",
          yAxisIndex: 0,
          data: [],
          itemStyle: {
            normal: {
              color: "#199ED8",
              label: {
                show: true, // 开启显示
                position: "top", // 在上方显示
                textStyle: {
                  // 数值样式
                  color: "blank",
                  fontSize: 16,
                },
              },
            },
          },
        },
        {
          name: "停车时长",
          type: "bar",
          yAxisIndex: 1,
          data: [],
          itemStyle: {
            normal: {
              color: "#FF9900",
              label: {
                show: true, // 开启显示
                position: "top", // 在上方显示
                textStyle: {
                  // 数值样式
                  color: "blank",
                  fontSize: 16,
                },
              },
            },
          },
        },
      ],
    };
  },
  components: {
    graphTitle,
  },
  watch: {
    // 夜间停车记录趋势
    nightParktableData: {
      handler(value) {
        this.dateList = [];
        this.seriesValue3[0].data = [];
        this.seriesValue3[1].data = [];
        for (var x in value.duration) {
          // console.log(x + "=" + value.duration[x]);
          this.seriesValue3[0].data.push(value.exitCount[x] == 0 ? '' : value.exitCount[x]);
        }
        for (var b in value.exitCount) {
          this.seriesValue3[1].data.push(parseInt(value.duration[b] / 3600) == 0 ? '' : parseInt(value.duration[b] / 3600));
        }
        this.drawGraph();
      },
      deep: true,
    },
  },
  methods: {
    drawGraph() {
      let that = this;
      let option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          formatter(params) {
            console.log(params)
            // const res = that.nightParktableData.find((item) => {
            //   return item.dataDate == params[0].axisValue;
            // });
            let tooltip = "";
            let marker0 = params[0] && params[0].marker ? params[0].marker : "";
            let marker1 = params[1] && params[1].marker ? params[1].marker : "";
            // let marker2 = params[2] && params[2].marker ? params[2].marker : "";
            tooltip += params[0].axisValue + "</br>";
            if (marker0) {
              tooltip += marker0 + `总停车次数: ${params[0].value == '' ? 0 : params[0].value}次` + "</br>";
            }
            if (marker1) {
              tooltip += marker1 + `总停车时长: ${params[1].value == '' ? 0 : params[1].value}时` + "</br>";
            }
            // if (marker2) {
            //   tooltip += marker2 + `上月同期停车记录数: ${res.parkingCntMonth} 次` + "</br>";
            // }
            // tooltip += `停车记录总数: ${res.allPark}(次)` + "</br>";
            // console.log(res);
            // console.log(params);
            return tooltip;
          },
        },
        toolbox: {},
        legend: {
          data: ["停车次数", "停车时长"],
        },
        xAxis: [
          {
            type: "category",
            // data: this.dateList,
            data: [
              "0-1小时",
              "1-2小时",
              "2-3小时",
              "3-4小时",
              "4-5小时",
              "5-6小时",
              "6-7小时",
              "7-8小时",
              "8-9小时",
              "9-10小时",
              "10-11小时",
              "11-12小时",
              "12-13小时",
              "13-14小时",
              "14-15小时",
              "15-16小时",
              "16-17小时",
              "17-18小时",
              "18-19小时",
              "19-20小时",
              "20-21小时",
              "21-22小时",
              "22-23小时",
              "23-24小时",
              "24小时以上",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisTick: {
              show: false,
            },
            // 展示网格线
            splitLine: {
              show: false,
            },
          },
        ],
        yAxis: [
          {
            name: "停车次数(次)",
            type: "log",
            logBase: 10,
            axisTick: {
              show: false,
            },
              minorTick: {
                show: true
              },
            axisLabel: {
              show: true,
              formatter: "{value}", // 左侧Y轴文字显示
              textStyle: {
                color: "blank",
              },
            },
              nameTextStyle: {
              align: "right",
              padding: [20, 10, 10, 10]
            },
           
            // 展示网格线
            splitLine: {
              show: true,
            },
          },
          {
            name: "停车时长(时)",
            position: "right",
            type: "log",
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              formatter: "{value}", // 右侧Y轴文字显示
              textStyle: {
                color: "blank",
              },
            },
            // 展示网格线
            splitLine: {
              show: false,
            },
          },
        ],
        series: this.seriesValue3,
      };
      let circularChart;
      circularChart = this.$echarts.init(document.getElementById("chart"));
      circularChart.setOption(option);
    },
  },
  mounted() {
    this.drawGraph();
  },
  created() {},
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.cylindricalchart
  width 100%
  height 400px
  overflow hidden
  margin-top 25px
</style>
